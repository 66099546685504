import React, { useState } from "react"
import {
  Container,
  TextField,
  CircularProgress,
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  CardMedia,
  CardContent,
  Card,
  useMediaQuery,
} from "@material-ui/core"
import Hero from "../components/hero"
import axios from "axios"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Briscoe from "../img/thisisme.jpg"

const useStyles = makeStyles(theme => ({
  submissionText: {
    fontSize: "1.2rem",
    fontFamily: "Nunito",
    padding: "50px",
    textAlign: "center",
  },
  bottom: {
    paddingBottom: "20px",
  },
  mainPaper: {
    backgroundColor: "#ffffffcc",
    backdropFilter: "blur(12px)",
  },
  bodyText: {
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    textAlign: "left",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "3px",
      fontSize: "1.05rem",
    },
  },
  cardCaption: {
    fontFamily: "Open Sans",
    textAlign: "center",
  },
  blur: {
    backdropFilter: "blur(10px)",
  },
  card: {
    borderRadius: "25px",
  },
}))

function ThisIsMeForm() {
  const classes = useStyles()
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [groupName, setGroupName] = useState("")
  const [text, setText] = useState("")
  const [formState, setFormState] = useState("initial")

  const LinkCheck = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  const numCheck = /^\d{11}$/
  let responseObj = null

  function handleSubmit() {
    setFormState("loading")
    axios
      .post(
        "https://gi9tdplnub.execute-api.us-east-1.amazonaws.com/default/thisisme",
        {
          name: name,
          number: number,
          groupName: groupName,
          text: text,
        }
      )
      .then(
        response => {
          responseObj = response.data
          if (responseObj.status == "success") {
            setFormState("done")
          } else {
            setFormState("error")
          }
        },
        error => {
          setFormState("error")
        }
      )
  }

  if (formState == "loading") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <CircularProgress size={150} thickness={1.5} />
        <br />
      </Grid>
    )
  } else if (formState == "error") {
    if (responseObj) {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error.{responseObj.info}
          </Typography>
        </div>
      )
    } else {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error. You may have made too many requests. Please try
            again later.
          </Typography>
        </div>
      )
    }
  } else if (formState == "done") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <Typography className={classes.submissionText}>
          Thank you for your submission! Your email has been sent to the team.
        </Typography>
        <br />
      </Grid>
    )
  } else {
    return (
      <Container>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pamper-name"
          label="Your Name"
          name="pamper-name"
          error={LinkCheck.test(name)}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="num-1"
          label="Your Mobile (without spaces)"
          name="num-1"
          error={!numCheck.test(number)}
          helperText="Please enter a valid UK mobile number (without spaces)."
          value={number}
          onChange={e => setNumber(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="group-name"
          label="Group/School Name"
          name="group-name"
          error={LinkCheck.test(groupName)}
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          required
          fullWidth
          id="general-text"
          label="Message to the Team"
          placeholder="Your message here..."
          multiline
          rows={8}
          margin="normal"
          error={LinkCheck.test(text)}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <br />
        <div className={classes.bottom}>
          {name && number && groupName && text ? (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                backgroundColor: "#0072ab",
                color: "white",
                borderRadius: "50px",
              }}
              onClick={() => handleSubmit()}
            >
              Submit Form
            </Button>
          ) : (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                borderRadius: "50px",
              }}
              color="primary"
              disabled
            >
              Enter missing details to submit
            </Button>
          )}
        </div>
      </Container>
    )
  }
}

export default function ThisIsMe() {
  const classes = useStyles()
  const isXl = useMediaQuery("(min-width: 1200px)")

  return (
    <div id="thisisme" className={classes.blur}>
      <Grid container justify="center" alignItems="center">
        <Container>
          <Hero
            title="This is Me"
            bodyText="An inspirational short film which has touched the hearts of many."
          />
          <div style={{ height: "2vh" }} />
          <>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justify="center"
              direction="row"
            >
              <Grid item sm={6} lg={isXl ? 4 : 7}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    component="img"
                    alt="Aston presenting This is Me at Briscoe school."
                    image={Briscoe}
                    title="This is Me"
                  />
                  <CardContent>
                    <Typography className={classes.cardCaption}>
                      Aston presenting This is Me at Briscoe School.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} lg={isXl ? 8 : 12}>
                <Typography className={classes.bodyText}>
                  An inspirational journey through thick and thin, This is Me is
                  a short film put together by Aston and his family. It began
                  life as a music video, recorded in secret as a surprise to
                  Aston, but after some thought, the team decided to supplement
                  it with content and turn it into a short film! Created to help
                  raise autism awareness and spread a positive message, This is
                  Me is a heartwarming experience that's touched the hearts of
                  many. The film has been shown to many schools in the local
                  area. To read more about these showings, please visit the{" "}
                  <AniLink
                    preventScrollJump
                    to="/events/"
                    paintDrip
                    hex="#d9e5fd"
                  >
                    Events
                  </AniLink>{" "}
                  page.
                </Typography>
                <Typography className={classes.bodyText}>
                  None of this would have been possible without the help of the
                  amazing John Summerton of{" "}
                  <a
                    rel="noopen noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/basildonstudio/"
                  >
                    Basildon Recording Studio
                  </a>{" "}
                  and our wonderful Averyettes: Jessica Richardson, Latalia
                  Pickleman, Rebecca Stanford, Sophie Griffiths and Skye Stack.
                  Thank you to everyone involved!
                </Typography>
                <Typography className={classes.bodyText}>
                  Are you a school or group looking to book a showing of This is
                  Me? Use the form below to submit a booking request. We'll
                  reply back as soon as we can! All showings are 100% free.
                </Typography>
              </Grid>
            </Grid>
            <ThisIsMeForm />
          </>
        </Container>
      </Grid>
    </div>
  )
}
